<template>
  <v-row v-if="team !== null">
    <template v-for="user in team">
      <v-col
        :key="user.uid"
        cols="12"
        lg="4"
        md="4"
        sm="6"
        xl="4"
      >
        <TeamMemberCard :member="user" />
      </v-col>
    </template>
  </v-row>
</template>

<script>

import TeamMemberCard from "./TeamMemberCard"

export default {
	name: 'OrganizationGrid',
	components: {
		TeamMemberCard
	},
  props: {
    team: {
      type: Array, 
      default: () => []
    }
  },
	data: () => ({
	}),
}
</script>
